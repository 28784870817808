<template>
  <div class="profile-general v-step-general-section">
    <div class="title">
      <h1>{{ $t('profile.general.title') }}</h1>
    </div>
    <div class="content">
      <div class="image">
        <div class="left">
          <VueGravatar v-if="!user.image" :size="120" :email="user.email" default-img="retro" />
          <img v-if="user.image" :src="user.image" alt="user image" />
          <AvatarCropper
            trigger="#pick-avatar"
            :labels="{ submit: 'Submit', cancel: 'Cancel' }"
            :mimes="'image/png, image/jpeg'"
            :upload-handler="handleUpload"
          />
        </div>
        <div class="right">
          <div class="buttons">
            <div class="upload">
              <button class="primary-full" :class="{ spinner: isAvatarLoading }" id="pick-avatar">
                {{ $t('profile.general.upload-photo') }}
              </button>
            </div>
            <div class="delete" @click="deleteAvatar" :class="{ spinner: isAvatarDeleteLoading }" v-if="user.image">
              <button class="orange">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <div class="text">
            <p>
              {{ $t('profile.general.image-type-allowed-and-size') }}
              <br />
              {{ $t('profile.general.image-cache-disclamer') }}
            </p>
          </div>
        </div>
      </div>
      <div class="username">
        <label for="username">{{ $t('profile.general.username') }}</label>
        <div class="custom-input primary">
          <CustomInput
            type="text"
            inputClasses="box col-all-12"
            name="username"
            :placeholder="$t('profile.general.username_placeholder')"
            v-model="$v.user.username.$model"
            :model="$v.user.username"
            :error="fieldsErrors.username"
          />
        </div>
      </div>
      <div class="email">
        <label for="email">{{ $t('profile.general.email') }}</label>
        <div class="custom-input primary">
          <CustomInput
            type="text"
            inputClasses="box col-all-12"
            name="email"
            :placeholder="$t('profile.general.email_placeholder')"
            v-model="$v.user.email.$model"
            :model="$v.user.email"
            :error="fieldsErrors.email"
          />
        </div>
      </div>
      <div class="save-button">
        <button class="orange-full" :class="{ spinner: isLoading }" @click="save">
          {{ $t('profile.general.save-changes') }}
        </button>
      </div>
      <div class="linked-accounts subtitle">
        <h3>{{ $t('profile.general.linked-accounts') }}</h3>
        <div v-if="!user.steamUsername" class="link-steam-account link-account col-lgpc-5 col-mdpc-8">
          <div class="row">
            <div class="icon col-all-2">
              <i class="fab fa-steam"></i>
            </div>
            <div class="platform-name col-pc-4 col-lgmobile-4 col-mdmobile-5 col-smobile-5">
              <h2>{{ $t('profile.general.steam') }}</h2>
            </div>
            <div class="link col-pc-6 col-lgmobile-6 col-mdmobile-5 col-smobile-5">
              <button
                class="primary col-mdmobile-displaynone-important col-smobile-displaynone-important"
                :class="{ spinner: isSteamLinkLoading }"
                @click="linkSteamAccount"
              >
                {{ $t('profile.general.link-your-account') }}
              </button>
              <button
                class="primary col-pc-displaynone-important col-lgmobile-displaynone-important"
                :class="{ spinner: isSteamLinkLoading }"
                @click="linkSteamAccount"
              >
                {{ $t('profile.general.link') }}
              </button>
            </div>
          </div>
          <p v-if="$route.query.link && $route.query.link === 'steam' && $route.query.error" class="error">
            {{ $t('errors.' + _.toUpper($route.query.error)) }}
          </p>
        </div>
        <div v-if="user.steamUsername" class="linked-steam-account linked-account col-lgpc-5 col-mdpc-8">
          <div class="row">
            <div class="icon col-pc-2 col-lgmobile-2">
              <i class="fab fa-steam"></i>
            </div>
            <div class="linked-name col-all-7">
              <p class="account-name" :title="user.steamUsername">{{ user.steamUsername }}</p>
              <p class="platform-name">{{ $t('profile.general.steam') }}</p>
            </div>
            <div
              class="unlink col-pc-3 col-lgmobile-3 col-mdmobile-displaynone-important col-smobile-displaynone-important"
            >
              <button
                class="primary-full"
                :class="{ spinner: isSteamLinkLoading }"
                :disabled="this.$store.state.auth.user && !this.$store.state.auth.user.email.includes('@')"
                @click="unlinkSteamAccount"
              >
                {{ $t('profile.general.unlink') }}
              </button>
            </div>
          </div>
          <div class="mobile-row col-pc-displaynone-important col-lgmobile-displaynone-important">
            <div class="unlink col-mobile-12">
              <button
                class="primary-full col-mobile-12"
                :class="{ spinner: isSteamLinkLoading }"
                :disabled="this.$store.state.auth.user && !this.$store.state.auth.user.email.includes('@')"
                @click="unlinkSteamAccount"
              >
                {{ $t('profile.general.unlink') }}
              </button>
            </div>
          </div>
        </div>
        <div class="link-discord-account link-account col-lgpc-5 col-mdpc-8">
          <div class="row">
            <div class="icon col-all-2">
              <i class="fab fa-discord"></i>
            </div>
            <div class="platform-name col-all-5">
              <h2>{{ $t('profile.general.discord') }}</h2>
            </div>
            <div
              class="coming-soon col-pc-5 col-lgmobile-5 col-mdmobile-displaynone-important col-smobile-displaynone-important"
            >
              <button class="warning-full">
                {{ $t('profile.general.coming-soon') }}
              </button>
            </div>
          </div>
          <div class="mobile-row col-pc-displaynone-important col-lgmobile-displaynone-important">
            <div class="coming-soon col-all-12">
              <button class="warning-full col-all-12">
                {{ $t('profile.general.coming-soon') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import { required, email } from 'vuelidate/lib/validators'
import validationMessage from '@/validationMapping'
import AvatarCropper from 'vue-avatar-cropper'
import VueGravatar from 'vue-gravatar'

export default {
  name: 'general',
  components: {
    AvatarCropper,
    VueGravatar
  },
  data() {
    return {
      isLoading: false,
      isSteamLinkLoading: false,
      isAvatarLoading: false,
      isAvatarDeleteLoading: false,
      fieldsErrors: {},
      steamLink: {},
      user: {
        email: this.$store.state.auth.user ? this.$store.state.auth.user.email : '',
        username: this.$store.state.auth.user ? this.$store.state.auth.user.username : '',
        image: this.$store.state.auth.user ? this.$store.state.auth.user.image : '',
        steamId64: this.$store.state.auth.user ? this.$store.state.auth.user.steamId64 : '',
        steamUsername: this.$store.state.auth.user ? this.$store.state.auth.user.steamUsername : ''
      },
      signedRequest: {}
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      username: {
        required
      }
    }
  },
  apollo: {
    steamLink: {
      query: require('@/graphql/user/fetchSteamLink.gql'),
      update: function(data) {
        if (
          this.$store.state.auth.user &&
          (this.$store.state.auth.user.steamId64 !== data.me.steamId64 ||
            this.$store.state.auth.user.steamUsername !== data.me.steamUsername)
        ) {
          this.$store.dispatch('profile/updateUserObject', {
            steamId64: data.me.steamId64,
            steamUsername: data.me.steamUsername,
            image: data.me.image
          })
          this.user.steamId64 = this.$store.state.auth.user.steamId64
          this.user.steamUsername = this.$store.state.auth.user.steamUsername
          this.user.image = this.$store.state.auth.user.image
        }

        return data.me
      },
      fetchPolicy: 'no-cache',
      subscribeToMore: {
        document: require('@/graphql/user/subscribeToSteamLink.gql'),
        variables() {
          return {
            userId: this.$store.state.auth.user ? this.$store.state.auth.user.id : null
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const steamLinkData = subscriptionData.data.user

          return {
            me: {
              ...steamLinkData.node
            }
          }
        }
      }
    }
  },
  methods: {
    save() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          email: validationMessage(this.$v.user.email),
          username: validationMessage(this.$v.user.username)
        }

        this.isLoading = false
        return
      }

      this.$store
        .dispatch('profile/updateUser', { email: this.user.email, username: this.user.username })
        .then(() => {
          this.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })

            if (Object.values(extensions).includes('EMAIL_ALREADY_USED')) {
              this.fieldsErrors.email = this.$t('errors.EMAIL_ALREADY_USED')
            } else {
              this.fieldsErrors = {
                username: true,
                email: true
              }
            }
          })
        })
    },
    handleUpload(cropper) {
      this.isAvatarLoading = true

      const _this = this
      const canvas = cropper.getCroppedCanvas()
      canvas.toBlob(
        function(blob) {
          if (blob.size > 800000) {
            _this.$store.dispatch('errorManager/showError', { error: 'FILE_TOO_LARGE' })
            return
          }

          _this.$store.dispatch('profile/getS3SignedUrlForAvatar').then(response => {
            fetch(response.signedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'image/png'
              },
              body: new File([blob], 'avatar.png')
            })
              .then(response2 => {
                _this.$store.dispatch('profile/updateUser', { image: response.url }).then(() => {
                  _this.isAvatarLoading = false

                  _this.user.image = _this.$store.state.auth.user.image

                  _this.$notify({
                    type: 'success',
                    title: _this.$t('notifications.save.title'),
                    text: _this.$t('notifications.save.text')
                  })
                })
              })
              .catch(error => {
                _this.isAvatarLoading = false
                _this.$store.dispatch('errorManager/showError', { error: error })
              })
          })
        },
        'image/png',
        0.85
      )
    },
    deleteAvatar() {
      this.isAvatarDeleteLoading = true

      this.$store.dispatch('profile/updateUser', { image: 'REMOVED' }).then(() => {
        this.isAvatarDeleteLoading = false

        this.user.image = this.$store.state.auth.user.image

        this.$notify({
          type: 'success',
          title: this.$t('notifications.save.title'),
          text: this.$t('notifications.save.text')
        })
      })
    },
    linkSteamAccount() {
      window.location.replace('//' + process.env.VUE_APP_API_ENDPOINT + '/link/steam/openid')
    },
    unlinkSteamAccount() {
      this.$swal({
        title: this.$t('profile.general.unlink-dialog.title'),
        text: this.$t('profile.general.unlink-dialog.text'),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('profile.general.unlink')
      }).then(result => {
        if (result.value) {
          this.isSteamLinkLoading = true

          this.$store.dispatch('profile/updateUser', { steamId64: 'REMOVED' }).then(() => {
            this.isSteamLinkLoading = false

            this.user.steamId64 = this.$store.state.auth.user.steamId64
            this.user.steamUsername = this.$store.state.auth.user.steamUsername

            this.$notify({
              type: 'success',
              title: this.$t('notifications.save.title'),
              text: this.$t('notifications.save.text')
            })
          })
        }
      })
    }
  }
}
</script>
